import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'
import FreeWastewaterAudit from '@components/forms/free-wastewater-audit'

const FreeWasteWaterAuditPage = () => {
  return (
    <Layout pageTitle="Free Wastewater Audit">
      <Container fluid="xxl">
        <Row>
          <Col>
            <div className="hero-banner large-hero light-hero">
              <StaticImage
                className="hero-image"
                src="../../../media/wessuc_default_hero.png"
                alt=""
                aspectRatio={3 / 1}
                layout="fullWidth" 
              />
              <div className="hero-text">
                <h1>
                  We'll show you better ways to handle your wastewater. On the spot.
                </h1>
              </div>
            </div>
            <div className="section"></div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="text-lg bg-gray section-pad">
        <Container fluid="xxl">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col>
              <p className="bold-2 default-text">
                We call it our "quick 'n' dirty wastewater audit" but our recommendations are always clean 'n' clear. Here's what you get:
              </p>
              <ul>
                <li>30-60 minute conversation about your processes, problems and goals</li>
                <li>on-the-spot recommendations for process, disposal and treatment improvements</li>
                <li>samples of your process wastewater analyzed at no charge</li>
                <li>	recommendations for changes to wastewater management to decrease costs, gain efficiencies and increase compliance</li>
              </ul>
            </Col>
            <Col md={12}>
              <FreeWastewaterAudit />
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}

export default FreeWasteWaterAuditPage